body {
  margin: 0;
  background: black;
  font-family: roboto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}




:root {
  --bg: black;
  --bg-accent: black;
  --text-color: #dadce1;
  --nav-size: 60px;

  --border-radius: 8px;
  --speed: 500ms;

}

* {
  font-family: Arial;
  font-size: 14px;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  color: var(--text-color);
  text-decoration: none;;
}
li {
  color: var(--text-color);
  text-decoration: none;;
}

/* Top Navigation Bar */

/* <nav> */
.navbar {
  height: var(--nav-size);
  background-color: var(--bg);
}

/* <ul> */

.navbar-nav-container {
  max-width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 17.5% 60% 17.5% 5%;
  align-items: center;
  justify-content: right;
  background-color: var(--bg);

}
.navbar-nav {
  max-width: 100%;
  height: 100%;
  display: flex;

}

.navbar-center{
  max-width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center
}

/* <li> */
.nav-item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.fill-page{
  min-height: 98vh;
  height: auto;
  width: 100%;
  display: inline-block;
  overflow: visible;


}

/* Icon Button */
.icon-button {
  height: 50%;
  background-color: transparent;
  margin-right: 15px;
  margin-left: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: filter 300ms;
}

.icon-button:hover {
  filter: brightness(1.2);
}

.icon-button svg { 
  fill: var(--text-color);
  width: 20px;
  height: 20px;
}

.icon-left{
  max-height:100%;
  text-align: center;
}

.icon-left-img{

  align-items: center;
  justify-content: center;
  width:auto;
  max-height:35px;
}

.nav-image {
  display: flex;
  align-items: center;
  justify-content: left;

}

.nav-image {
  display: flex;
  align-items: center;
  justify-content: right;

}

/* Icon Button */
.img-button {

  background-color: transparent;
  border-radius: 50%;
  padding: 5px;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: filter 300ms;
}

.nav-button{
  height:50%;
  max-width: 225px;
  min-width: 100px;
  background-color: #5E79FB;
  border-radius: 2%;
  margin-left: 7.5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}

.nav-button:hover {
  filter: brightness(1.2);
}

.logo-img{
  max-height: calc(var(--nav-size) * 0.8);
}
.lang-img{
  max-height: calc(var(--nav-size) * 0.25);
}
/* Dropdown Menu */

.dropdown {
  position: absolute;
  top: 62px;
  right:-15vw;
  width: 90vw;
  background: rgba(0, 0, 0, 0.85);
  border-radius: var(--border-radius);
  overflow: hidden;
  transition: height var(--speed) ease;
}

.SectionHeader{
  background-color: transparent;
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 10px;
}

.SectionSubHeader{
  background-color: transparent;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

.menu {
  width: 100%;
}

.menu-item {
  height: var(--nav-size);
  display: flex;
  align-items: center;
  transition: background var(--speed);
}

.menu-item-dropdown {
  height: var(--nav-size);
  display: grid;
  grid-template-columns: 20% 80%;
  align-items: center;
  transition: background var(--speed);
}

.menu-item .icon-button {
  margin-right: 0.5rem;
}

.Link{
  height:50%
}


.menu-item .icon-button:hover {
  filter: none;
}


.div-3{
  width: 100%;
  height: calc(var(--nav-size)*0.8);
  display: grid;
  grid-template-columns: 33.3% 33.3% 33.3%;
  align-items: center;
  font-size: 13px;
  margin: 5px;

}


.div-max-size > img {
  max-height: 100%;
  max-width: 100%;
  height: auto;
  width: auto;

  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
}

.div-ico-left{
  width:100%;
  height:100%;
  display: grid;
  grid-template-columns: 20% 80%;
  align-items: center;

}

/* CSSTransition classes  */
.menu-primary-enter {
  position: absolute;
  transform: translateX(-110%);
}
.menu-primary-enter-active {
  transform: translateX(0%);
  transition: all var(--speed) ease;
}
.menu-primary-exit {
  position: absolute;
}
.menu-primary-exit-active {
  transform: translateX(-110%);
  transition: all var(--speed) ease;
}


.menu-secondary-enter {
  transform: translateX(110%);
}
.menu-secondary-enter-active {
  transform: translateX(0%);
  transition: all var(--speed) ease;
}
.menu-secondary-exit {

}
.menu-secondary-exit-active {
  transform: translateX(110%);
  transition: all var(--speed) ease;
}

.div-4{
  width: 100%;
  height: 20vh;
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  align-items: center;
  justify-items: center;


}

.div-comp{
  width: 100%;
  min-height: 30vh;
  height: fit-content;
  max-height: 40vh;
  display: inline-flex;
  align-items: center;
  justify-items: center;
  align-content: center;
  justify-content: center;


}

.div-max-size {
  height: 40%;
  width: 40%;

  position: relative;
}

.centered_blue_button{

  height:40px;
  width:100%;
  min-width: 100px;
  background-color: #5E79FB;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  font-weight: bold;


}

.centered_blue_button:hover {
  filter: brightness(1.2);
}

.centered-div{
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}

.overlap-div-left{
  width: 70%;
  min-height: 20vh;
  position: relative;
  display: grid;
  grid-template-columns: 30% 70%;
}

.overlap-div-right{
  width: 70%;
  min-height: 20vh;
  height: fit-content;
  position: relative;
  display: inline-grid;
  grid-template-columns: 70% 30%;
  font-family: Spezia,Arial,Helvetica,sans-serif;
}

.div-image-fit{
  height: 100%;
  width: 100%;
  position: relative;
}

.div-image-fit > img {
  max-height: 100%;
  max-width: 100%;
  height: auto;
  width: auto;

  position: absolute;
  top: 50%;
  left: 50%;
  padding-right: 9%;
  transform: translate(-50%, -50%);
}

.overlap-text{
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: justify;
  margin-top: 5px;
  margin-bottom: 10px;
  color: var(--text-color);
  border-left: 3px solid #009BA2;
  padding-left: 9%;
  padding-right: 9%;
  line-height: 160%;
}

.div-3-app{
  width: 70%;
  height: 50vh;
  position: relative;
  display: grid;
  grid-template-columns: 33.3% 33.3% 33.3%;
}

.banner{
  position:relative;
  display:inline-block;
}
.banner:after{
  content:'';
  position:absolute;
  left:0; top:0;
  width:100%; height:100%;
  display:inline-block;
  background: linear-gradient(to bottom, rgba(0, 0, 10, .9),rgba(50,50,100,.2) 20%
  ,rgba(50,50,100,.2) 80% ,rgba(0, 0, 10, .9));
}

.banner img{
  display:block;
}

.bottom-bar{
  width: 100%;
  height: 150px;
  color: transparent;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-rows: 80% 10% 10%;
  margin-bottom: 10px;
}

.bottom-bar-inner{
  min-width: 900px;
  height: 100%;
  display: grid;
  grid-template-columns: 33% 33% 33%;
}

.copyright{
  background-color: transparent;
  font-size: 14px;
  width: 100%;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
  font-style: italic;
  color: #a6a9aa;
}

.point-cloud-selector{
  width: 99%;
  margin-left: 0.5%;
  height: 4vh;
  min-height: 40px;
  max-height: 60px;
  color: transparent;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 20% 20% 20% 20% 20%;
  margin-bottom: 10px;
}
.point-cloud-selector-button{
  height: 100%;
  width: 100%;
  color: var(--text-color);
  text-align: center;
  background-color: transparent;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  font-weight: bold;
}
.point-cloud-selector-button:hover {
  filter: brightness(1.2);
}